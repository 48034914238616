import { useRouteMatch, useParams } from 'react-router-dom';
import EventName from './EventName';
import QualifyingRoundHighJumpLongJump from '../QualifyingRoundHighJumpLongJump';

const PlaceHolder = () => {
  const params = useParams();
  const base = useRouteMatch('/:user/:type/:scoreType1/:scoreType2/:sportEvent');
  console.log(base);
  if (
    base?.params?.scoreType1 + base?.params?.scoreType2 ===
    'qualifying round (high jump  long jump )'
  ) {
    return <QualifyingRoundHighJumpLongJump />;
  }
  return (
    <div>
      <EventName sport_event={params.sportEvent} />
      <div className="my-8 p-5 bg-gradient-to-br from-blue-75 to-purple-75 rounded-lg">
        <div
          className="text-center p-8 text-white font-bold rounded-lg"
          style={{
            background: `url(/assets/sfa_online/node_modules/knockout-component/src/direflow-components/knockout-component/components/images/pattern.png) no-repeat -5% , conic-gradient(from 51.11deg at 50% 58.75%, #8E2DE2 0deg, #4A00E0 360deg)`,
          }}
        >
          There are no draws created for this Sport Event yet
        </div>
      </div>
    </div>
  );
};

export default PlaceHolder;
