import search from '../../assets/videos/Search.mp4';
import gold from '../../assets/img/gold.svg';
import silver from '../../assets/img/silver.svg';
import bronze from '../../assets/img/bronze.svg';
import { getDateInMonthDateYear, getTimeIn12HourFormat } from '../../constants/DateFunctions';

function TextColumn({ row, columnKey, checkStatus, callbackRow, bgCallback, hideDecimal }) {
  const rowCopy = row;
  row = (callbackRow && callbackRow(row, columnKey)) || row;
  const bg = bgCallback && bgCallback(row, columnKey, rowCopy);

  if (columnKey === 'score') {
    if (row?.match_status === 'DIGITAL_SCORE' || row?.status === 'DIGITAL_SCORE')
      return <td className="pl-4 py-2.5 text-xs text-gray-750">Yet To Play</td>;
    if (row?.match_status === 'NO_SHOW' || row?.status === 'NO_SHOW')
      return <td className="pl-4 py-2.5 text-xs text-gray-750">No Show</td>;
    if (row[columnKey]?.toLowerCase() === 'nan') {
      return <td className="pl-4 py-2.5 text-xs text-gray-750">-</td>;
    }
  }
  if (columnKey === 'round_point') {
    if (
      rowCopy?.match_status?.toLowerCase() === 'digital_score' ||
      rowCopy?.match_status?.toLowerCase() === 'digital_scoring' ||
      rowCopy?.match_status?.toLowerCase() === 'yet to play'
    )
      return <td className={`pl-4 py-2.5 text-xs text-gray-750 ${bg}`}>Yet to Play</td>;
    if (!row[columnKey] || row[columnKey].toLowerCase() === 'nan') {
      return <td className={`pl-14 py-2.5 text-xs text-gray-750 ${bg}`}>-</td>;
    }
  }
  if (columnKey === 'round_name') {
    if (!row[columnKey] || row[columnKey].toLowerCase() === 'nan') {
      return <td className={`pl-14 py-2.5 text-xs text-gray-750 ${bg}`}>-</td>;
    } else return <td className={`pl-4 py-2.5 text-xs text-gray-750 ${bg}`}>{row[columnKey]}</td>;
  } else if (columnKey === 'sfa_id')
    return (
      <td className={`pl-4 py-2.5 text-xs text-gray-750 ${bg}`}>
        {row && row?.athlete_name && row?.athlete_name[columnKey]}
      </td>
    );
  else if (columnKey === 'lane') {
    if (
      row[columnKey] === '' ||
      row[columnKey] === null ||
      row[columnKey].toLowerCase() === 'nan'
    ) {
      return <td className={`pl-14 py-2.5 text-xs text-gray-750 ${bg}`}>-</td>;
    }
  } else if (columnKey === 'best_attempt') {
    if (
      row[columnKey] === '' ||
      row[columnKey] === null ||
      row[columnKey].toLowerCase() === 'nan'
    ) {
      return <td className={`pl-14 py-2.5 text-xs text-gray-750 ${bg}`}>-</td>;
    }
  } else if (columnKey === 'timing') {
    if (row[columnKey]?.toLowerCase() === 'nan')
      return <td className={`pl-4 py-2.5 text-xs text-gray-750 text-left ${bg}`}>-</td>;
    if ((!row[columnKey] && row[columnKey] !== 0) || row[columnKey] === 'Yet To Play') {
      return <td className={`pl-4 py-2.5 text-xs text-gray-750 text-left ${bg}`}>-</td>;
    }
    return <td className={`pl-4 py-2.5 text-xs text-gray-750 ${bg}`}>{row?.timing}</td>;
  } else if (
    columnKey === 'won' ||
    columnKey === 'lost' ||
    columnKey === 'draw' ||
    columnKey === 'match_count'
  ) {
    if (row?.matches && row?.matches[columnKey] && row?.matches[columnKey] !== null)
      return (
        <td className={`pl-4 py-2.5 text-xs text-gray-750 ${bg}`}>{row?.matches[columnKey]}</td>
      );
    else return <td className={`pl-4 py-2.5 text-xs text-gray-750 ${bg}`}>-</td>;
  } else if (columnKey === 'score') {
    console.log(row);
    if (row[columnKey] === null || row[columnKey] === '') {
      return <td className="pl-24 py-2.5 text-xs text-gray-750">-</td>;
    } else if (typeof row[columnKey] === 'string') {
      return <td className={`pl-8 py-2.5 text-xs text-gray-750 ${bg}`}>{row[columnKey]}</td>;
    } else if (
      !row?.opponent_1?.score &&
      !row?.opponent_2?.score &&
      !row?.opponent_1?.score !== 0 &&
      !row?.opponent_2?.score !== 0
    )
      return (
        <td className="pl-4 py-2.5 text-xs text-gray-750">
          {checkStatus
            ? row?.status.toLowerCase() === 'digital_score'
              ? 'Yet To Play'
              : row.status
            : '-'}
        </td>
      );
    else
      return (
        <td className="pl-4 py-2.5 text-xs text-gray-750">
          {row?.opponent_1?.score ? `${row?.opponent_1?.score}` : `${row?.opponent_2?.score}`}
        </td>
      );
  }
  return (
    <td className={`pl-4 py-2.5 text-xs text-gray-750 ${bg} `}>
      {(hideDecimal && parseInt(row[columnKey])) || (row && row[columnKey])}
    </td>
  );
}
function TextAndImage({ row, columnKey }) {
  if (row[columnKey]?.name)
    return (
      <td className="pl-4 py-2.5 text-xs text-gray-750">
        <img
          src={row[columnKey]?.image}
          alt=""
          className="rounded-full inline-block mr-3 flex-shrink-0"
          style={{ width: '32px', height: '32px' }}
        />
        <span>{row[columnKey]?.name}</span>
      </td>
    );
  else return <td className="pl-14 py-2.5 text-xs text-gray-750">-</td>;
}
function SwissInfoColumn({ row, columnKey, checkForDraws }) {
  console.log(checkForDraws, columnKey);
  return (
    <td
      className={`pl-4 py-2.5 text-xs text-gray-750 ${
        checkForDraws && row?.match_status?.toLowerCase() === 'draw'
          ? 'bg-gray-150'
          : columnKey &&
            ((row[columnKey]?.is_won && row[columnKey]?.is_won !== '-') ||
              (row?.is_won && row?.is_won !== '-'))
          ? 'bg-green-50'
          : ''
      }`}
    >
      <div className="flex text-xs gap-2">
        <img
          src={`${columnKey ? row[columnKey].athlete_name?.image : row?.athlete_name?.image}`}
          className="w-8 h-8 object-cover rounded-full"
          alt={''}
        />
        <div>
          <div className="flex gap-2 ">
            {columnKey ? row[columnKey].athlete_name?.name : row?.athlete_name?.name}
            <div className="text-gray-450">
              {' '}
              {columnKey ? row[columnKey].athlete_name?.sfa_id : row?.athlete_name?.sfa_id}
            </div>
          </div>
          <div className="text-gray-650">
            {' '}
            {columnKey ? row[columnKey].institution?.name : row?.institution?.name}
          </div>
        </div>
      </div>
    </td>
  );
}
function ResultButton({ row, columnKey }) {
  if (
    !row[columnKey]?.status ||
    row[columnKey]?.status === '' ||
    row[columnKey]?.status === 'nan'
  ) {
    return <td className="pl-4 py-2.5 text-xs text-gray-750">-</td>;
  }
  return (
    <td className="pl-4 py-2.5 text-xs text-gray-750">
      {row[columnKey]?.status !== 'Bronze' &&
      row[columnKey]?.status !== 'Silver' &&
      row[columnKey]?.status !== 'Gold' ? (
        <button
          className={`${
            row[columnKey]?.color?.toLowerCase() === 'green'
              ? 'bg-green-450 text-white'
              : 'bg-gray-300 text-gray-500'
          }  rounded-md w-28 px-4 py-1`}
        >
          {row[columnKey]?.status}
        </button>
      ) : (
        <button className="flex gap-2">
          <img
            src={
              row[columnKey].status === 'Gold'
                ? gold
                : row[columnKey].status === 'Silver'
                ? silver
                : bronze
            }
            width="25px"
            height="25px"
            alt="Medal"
          />
          <p>{row[columnKey]?.status}</p>
        </button>
      )}
    </td>
  );
}

function VideoColumn({ row, columnKey }) {
  return (
    <td className="pl-4 py-2.5 text-xs text-gray-750">
      <div className="text-sm text-gray-900 h-8 w-34 overflow-hidden rounded-md cursor-pointer">
        <video>
          <source src={row[columnKey] ? row[columnKey] : search} />
        </video>
      </div>
    </td>
  );
}
function OpponentColumn({ row, columnKey, priority, callback, checkForDraws }) {
  const result = priority && callback && callback(row, columnKey, priority);
  if (row[result || columnKey]?.name)
    return (
      <td
        className={`pl-4 py-2.5 text-xs text-gray-750 ${
          checkForDraws && row?.match_status?.toLowerCase() === 'draw'
            ? 'bg-gray-150'
            : row[result || columnKey]?.won
            ? 'bg-green-50'
            : ''
        }`}
      >
        <div className="flex rounded-full">
          <img
            src={row[columnKey]?.image}
            alt="dp"
            className="w-8 h-8 rounded-full inline-block mr-3"
          />
          <div>
            <div className="flex gap-1">
              <span className="text-gray-750">{row[result || columnKey]?.name}</span>
              {/* <span className="text-gray-450">{row[columnKey]?.sfa_id}</span> */}
            </div>
            <span className="text-gray-650">{row[result || columnKey]?.institution}</span>
          </div>
        </div>
      </td>
    );
  else return <td className="pl-14 py-2.5 text-xs text-gray-750">-</td>;
}
function DateTimeColumn({ row, columnKey }) {
  return (
    <td className="pl-4 py-2.5 text-xs text-gray-750 flex flex-col">
      <span>{getDateInMonthDateYear(row[columnKey])}</span>
      <span className="text-gray-400">{getTimeIn12HourFormat(row[columnKey])}</span>
    </td>
  );
}

function ResultMedalButton({ row, columnKey }) {
  return (
    <td className="pl-4 py-2.5 text-xs text-gray-750">
      {row[columnKey]?.medal === 'gold' ? (
        <>
          <img src={gold} alt="gold" className="inline-block w-5 h-5 mr-2" />
          <span>Gold (1st)</span>
        </>
      ) : (
        ''
      )}
      {row[columnKey]?.medal === 'silver' ? (
        <>
          <img src={silver} alt="silver" className="inline-block w-5 h-5 mr-2" />
          <span>Silver (2nd)</span>
        </>
      ) : (
        ''
      )}
      {row[columnKey]?.medal === 'bronze' ? (
        <>
          <img src={bronze} alt="bronze" className="inline-block w-5 h-5 mr-2" />
          <span>Bronze (3rd)</span>
        </>
      ) : (
        ''
      )}
      {row[columnKey]?.medal === '' || row[columnKey]?.medal === 'participated' ? (
        <>
          <span>Participated</span>
        </>
      ) : (
        ''
      )}
    </td>
  );
}
function IndexColumn({ _row, _columnKey, rowIndex }) {
  return <td className="pl-4 py-2.5 text-sm text-gray-750 whitespace-nowrap">{rowIndex}</td>;
}

export default function Table({ columns, rows, _data }) {
  return (
    <div className=" overflow-x-scroll w-full mt-4 md:mt-0 max-w-full border-collapse bg-red border border-gray-250 rounded-lg bg-white pb-3">
      <table
        className="tb text-left font-roboto overflow-x-scroll w-full whitespace-nowrap"
        style={{ minWidth: '900px' }}
      >
        <thead>
          <tr className="text-left border-b border-gray-300">
            {columns.map((column, i) => (
              <TableHeaderRow
                key={i}
                text={column.label}
                optionalClasses={column.optionalClasses}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row, index) => {
            return (
              <tr key={index}>
                {columns.map((column, key) => {
                  // console.log(column, key);
                  return <TableRenderRow key={key} rowIndex={index} column={column} row={row} />;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function TableHeaderRow({ text, optionalClasses }) {
  return (
    <>
      <th className={optionalClasses || 'block-flex flex-nowrap items-center pl-4 py-4'}>
        <span className="whitespace-nowrap text-gray-850 text-sm">{text}</span>
      </th>
    </>
  );
}

function TableRenderRow({ row, column, rowIndex }) {
  if (column.component === 'TextColumn')
    return (
      <TextColumn
        row={row}
        columnKey={column.key}
        checkStatus={column.checkStatus}
        callbackRow={column.callbackRow}
        bgCallback={column.bgCallback}
        hideDecimal={column.hideDecimal}
      />
    );

  if (column.component === 'TextAndImage') return <TextAndImage row={row} columnKey={column.key} />;

  if (column.component === 'ResultButton') return <ResultButton row={row} columnKey={column.key} />;

  if (column.component === 'VideoColumn') return <VideoColumn row={row} columnKey={column.key} />;

  if (column.component === 'OpponentColumn')
    return (
      <OpponentColumn
        row={row}
        columnKey={column.key}
        priority={column.priority}
        callback={column.callback}
        checkForDraws={column.checkForDraws}
      />
    );
  if (column.component === 'swissInfoColumn')
    return (
      <SwissInfoColumn row={row} columnKey={column.key} checkForDraws={column.checkForDraws} />
    );
  if (column.component === 'DateTimeColumn')
    return <DateTimeColumn row={row} columnKey={column.key} />;

  if (column.component === 'ResultMedalButton')
    return <ResultMedalButton row={row} columnKey={column.key} />;
  if (column.component === 'IndexColumn')
    return <IndexColumn row={row} columnKey={column.key} rowIndex={rowIndex + 1} />;
}
