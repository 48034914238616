import React from 'react';
import gold from '../../assets/img/gold.svg';
import silver from '../../assets/img/silver.svg';
import bronze from '../../assets/img/bronze.svg';

const MobileCard = ({
  Data: {
    athlete_name: athleteName = {},
    institution = {},
    matches = {},
    result = {},
    points = '',
    lane,
    position,
    best_attempt: bestAttempt,
    score,
    number_of_attempts: numberOfAttempts,
    timing,
  },
  showWatchVideo = false,
  isDirectFinals,
}) => {
  const TagClass = (color) => {
    if (color) {
      if (color?.toLowerCase() === 'green')
        return 'px-1.5 py-1 bg-green-450 text-white rounded text-xxs';
      else if (color?.toLowerCase() === 'gray')
        return 'px-1.5 py-1 text-gray-450 bg-gray-150 rounded text-xxs';
      else return `px-1.5 py-1 bg-${color?.toLowerCase()}-200 rounded text-xxs`;
    }
    return `flex justify-center align-center text-sm`;
  };

  return (
    <div
      className={`w-full flex flex-col border-b-2 border-gray-300 md:hidden sm:pl-3 pt-4 overflow-hidden ${
        Object.keys(matches).length ? 'h-28' : ''
      }`}
    >
      <div className="flex flex-row ">
        <div className={'flex justify-center items-start'}>
          <div
            className={'rounded-full bg-cover bg-center'}
            style={{ background: `url(${athleteName?.image})`, width: '32px', height: '32px' }}
          />
        </div>

        <div className={'sm:ml-3 flex flex-column justify-between items-stretch flex-grow w-full'}>
          <div className="flex justify-between flex-row sm:px-3 px-2 w-full">
            <div className={'flex items-center flex-grow justify-start'}>
              <div className={'text-sm tracking-wide truncate'} title={athleteName?.name}>
                {athleteName?.name}
              </div>
              <div className={'ml-1 text-xxs p-1 text-gray-450 bg-gray-15 rounded truncate'}>
                {athleteName?.sfa_id || athleteName?.name}
              </div>
            </div>
            <div className={'text-xs truncate text-gray-450'}>
              {(lane && 'Lane : ' + lane) ||
                (bestAttempt && 'Best Attempt: ' + bestAttempt) ||
                (numberOfAttempts && 'Attempts: ' + numberOfAttempts) ||
                (score && 'Score: ' + score) ||
                (position && 'Rank: ' + position)}
            </div>
          </div>

          <div className="flex justify-between sm:px-3 px-2 py-2 items-center w-full">
            <div className={'text-xs flex items-end justify-start'}>
              {institution.name}{' '}
              {timing && `• ${timing?.toLowerCase() !== 'nan' ? timing : '-'} sec`}
            </div>
            <div className={TagClass(result.color)}>
              {result.status?.toString()?.toLowerCase() === 'gold' ? (
                <img src={gold} alt={'gold'} style={{ height: '20px', marginRight: '5px' }} />
              ) : result.status?.toString()?.toLowerCase() === 'silver' ? (
                <img src={silver} alt={'silver'} style={{ height: '20px', marginRight: '5px' }} />
              ) : (
                result.status?.toString()?.toLowerCase() === 'bronze' && (
                  <img src={bronze} alt={'bronze'} style={{ height: '20px', marginRight: '5px' }} />
                )
              )}
              {result.status?.toString().toLowerCase() === 'gold' ||
              result.status?.toString().toLowerCase() === 'silver' ||
              result.status?.toString().toLowerCase() === 'bronze' ||
              result.status?.toString().toLowerCase() === 'yet to play' ||
              result.status?.toString().toLowerCase() === 'no show' ||
              result.status?.toString().toLowerCase() === 'no_show'
                ? result.status
                : isDirectFinals
                ? 'Participated'
                : result.color
                ? result.status
                : result.status && 'Rank ' + result.status}
            </div>
          </div>
          {Object.keys(matches).length > 0 && (
            <div className={'bg-gray-15 flex justify-evenly items-center text-xxs h-10'}>
              <div>Matches - {matches.match_count}</div>
              <div>Win - {matches.won || matches.win}</div>
              <div>Loss - {matches.lost || matches.loss}</div>
              <div>Draw - {matches.draw}</div>
              <div>Points - {points}</div>
            </div>
          )}
        </div>
      </div>
      {showWatchVideo && (
        <div className="bg-gray-15 text-right text-sm py-0.5 pr-px cursor-pointer">Watch Video</div>
      )}
    </div>
  );
};

export default MobileCard;
