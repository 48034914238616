import React from 'react';
import style from './styles/modal.module.scss';
import PropTypes from 'prop-types';

const Modal = ({ modalType, setShowModal, card }) => {
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <div className={style.modalContainer}>
      <div className={style.modal}>
        {modalType === 'score' && (
          <div className={style.modalHeader}>
            <h3 className={style.modalTitle}>Score</h3>
            <h3 className={style.modalTitle}>Match ID: {card.matchId}</h3>
          </div>
        )}

        {modalType === 'score' && (
          <div className={style.modalBody} onClick={closeModal}>
            <div dangerouslySetInnerHTML={{ __html: card.score }} />
          </div>
        )}
      </div>
    </div>
  );
};

Modal.propTypes = {
  modalType: PropTypes.string.isRequired,
  setShowModal: PropTypes.func.isRequired,
  card: PropTypes.object.isRequired,
};

export default Modal;
