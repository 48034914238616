import React from 'react';
import EventName from '../Component/EventName';
import Table from '../Component/Table';
import { useParticipantsList } from '../queries/digitalScoringHooks';
import { useParams } from 'react-router-dom';
import DetailItemLoader from 'components/DetailItemLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import MobileCard from '../Component/MobileCard';
import MobileTable from '../Component/MobileTable';

export default function DirectFinalsShooting() {
  const params = useParams();
  const sportEvent = params.sportEvent;
  const participantsListQueryData = useParticipantsList({
    sport_event: sportEvent,
  });

  const columns = [
    {
      key: ' ',
      label: '',
      component: 'TextColumn',
    },
    // {
    //   key: 'sl.no',
    //   label: 'Lane',
    //   component: 'TextColumn',
    // },
    {
      key: 'sfa_id',
      label: 'SFA ID',
      component: 'TextColumn',
    },
    {
      key: 'athlete_name',
      label: 'Athlete (Student) Name',
      component: 'TextAndImage',
    },
    {
      key: 'institution',
      label: 'Institution',
      component: 'TextAndImage',
    },
    {
      key: 'best_attempt',
      label: 'Best Attempt',
      component: 'TextColumn',
    },
    {
      key: 'result',
      label: 'Results',
      component: 'ResultButton',
    },
    // {
    //   key: 'video',
    //   label: 'Video',
    //   component: 'VideoColumn',
    // },
  ];

  return (
    <div>
      <EventName sport_event={sportEvent} />
      <div className="flex justify-between items-center p-4 md:hidden -mb-8 hidden">
        <p className="font-bold">Match Details</p>
        <FontAwesomeIcon icon={faSearch} className="text-gray-450" />
      </div>
      {/* <div className="my-8 p-5 bg-gradient-to-br from-blue-75 to-purple-75 rounded-lg"> */}
      <DetailItemLoader queryData={participantsListQueryData}>
        {({ data }) =>
          data.length > 0 ? (
            <>
              <div className="hidden md:block my-8 p-5 bg-gradient-to-br from-blue-75 to-purple-75 rounded-lg">
                <Table rows={data} columns={columns} />
              </div>
              {data.map((item, index) =>
                item.opponent_1 ? (
                  <MobileTable key={index} Data={item} />
                ) : (
                  <MobileCard key={index} Data={item} showWatchVideo={false} />
                )
              )}
            </>
          ) : (
            <div
              className="text-center p-8 text-white font-bold rounded-lg"
              style={{
                background: `url(/assets/sfa_online/node_modules/knockout-component/src/direflow-components/knockout-component/components/images/pattern.png) no-repeat -5% , conic-gradient(from 51.11deg at 50% 58.75%, #8E2DE2 0deg, #4A00E0 360deg)`,
              }}
            >
              Draws & Schedules will be updated soon. Thank you for your patience.
            </div>
          )
        }
      </DetailItemLoader>
    </div>
    // </div>
  );
}
