import React, { useEffect, useState } from 'react';
import EventName from '../Component/EventName';
import Table from '../Component/Table';
import {
  useMedalsList,
  useParticipantsList,
  useTabsRoundsList,
  useOverview,
} from '../queries/digitalScoringHooks';
import TabsRounds from '../Component/TabsRounds';
import Groups from '../Component/Groups';
import MedalsFinals from '../Component/MedalsFinals';
import DetailItemLoader from 'components/DetailItemLoader';
import MobileTable from '../Component/MobileTable';
import MobileCard from '../Component/MobileCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import TabsRoundsMobile from '../Component/TabsRoundsMobile';

export default function LeagueKnockout() {
  const [selected, setSelected] = useState('points_table');
  const [selected2, setSelected2] = useState('group_id-1');
  const sportEvent = '219-Badminton-Female-U-18';

  const tabRoundsListQueryData = useTabsRoundsList({
    sport_event: sportEvent,
  });

  const medalsListQueryData = useMedalsList({
    sport_event: sportEvent,
  });

  const participantsListQueryData = useParticipantsList({
    sport_event: sportEvent,
    tab_id: selected,
    group_id: selected2,
  });
  const overviewData = useOverview({
    sport_event: sportEvent,
  });

  useEffect(() => {
    participantsListQueryData.refetch();
  }, [selected, selected2]);

  const [Group, setGroup] = useState([]);

  const setTabs = (idx) => {
    setSelected(idx);
    setSelected2('group_id-1');
  };

  useEffect(() => {
    if (tabRoundsListQueryData.isLoading === false) document.querySelector('.tabs').click();
  }, [tabRoundsListQueryData.isLoading]);

  const setHeat = (idx) => {
    setSelected2(idx);
  };

  const setGroupOnTabs = (group) => {
    setGroup(group);
  };

  const columns = [
    {
      key: 'lane',
      label: 'Lane',
      component: 'TextColumn',
    },
    {
      key: 'sfa_id',
      label: 'SFA ID',
      component: 'TextColumn',
    },
    {
      key: 'athlete_name',
      label: overviewData?.data?.type === 'Team' ? 'Team ID' : 'Athlete (Student) Name',
      component: 'TextAndImage',
    },
    {
      key: 'institution',
      label: 'Institution',
      component: 'TextAndImage',
    },
    {
      key: 'no_of_jumps',
      label: 'Number of Jumps',
      component: 'TextColumn',
    },
    {
      key: 'result',
      label: 'Results',
      component: 'ResultButton',
    },
    {
      key: 'video',
      label: 'Video',
      component: 'VideoColumn',
    },
  ];

  return (
    <div>
      <EventName />
      {tabRoundsListQueryData?.data?.length > 0 ? (
        <>
          <TabsRounds
            tabRoundsListQueryData={tabRoundsListQueryData}
            setTabs={setTabs}
            selected={selected}
            setGroupOnTabs={setGroupOnTabs}
            enableScrollButtons
          />
          <TabsRoundsMobile
            tabRoundsListQueryData={tabRoundsListQueryData}
            setTabs={setTabs}
            selected={selected}
            setGroupOnTabs={setGroupOnTabs}
            enableScrollButtons
          />
        </>
      ) : null}
      <Groups Group={Group} setHeat={setHeat} selected2={selected2} />
      <MedalsFinals medalsListQueryData={medalsListQueryData} Group={Group} />
      <div className="flex justify-between items-center p-4 hidden -mb-8">
        <p className="font-bold">Match Details</p>
        <FontAwesomeIcon icon={faSearch} className="text-gray-450" />
      </div>
      {participantsListQueryData.data.length > 0 ? (
        <DetailItemLoader queryData={participantsListQueryData}>
          {(data) =>
            selected === 'winners' || selected === '' ? (
              ''
            ) : (
              <>
                <div className="my-8 p-5 bg-gradient-to-br from-blue-75 to-purple-75 rounded-lg">
                  <Table rows={data} columns={columns} />
                </div>
                {data.map((item, index) =>
                  item.opponent_1 ? (
                    <MobileTable key={index} Data={item} />
                  ) : (
                    <MobileCard key={index} Data={item} />
                  )
                )}
              </>
            )
          }
        </DetailItemLoader>
      ) : (
        <div className="my-8 p-5 bg-gradient-to-br from-blue-75 to-purple-75 rounded-lg">
          <div
            className="text-center p-8 text-white font-bold rounded-lg"
            style={{
              background: `url(/assets/sfa_online/node_modules/knockout-component/src/direflow-components/knockout-component/components/images/pattern.png) no-repeat -5% , conic-gradient(from 51.11deg at 50% 58.75%, #8E2DE2 0deg, #4A00E0 360deg)`,
            }}
          >
            Draws & Schedules will be updated soon. Thank you for your patience.
          </div>
        </div>
      )}
    </div>
  );
}
