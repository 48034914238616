import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

export default function Groups({ Group, setHeat, selected2, pool }) {
  const scrollDiv = useRef(null);
  const scroll = (scrollOffset) => {
    if (scrollDiv.current.scrollWidth > scrollDiv.current.clientWidth) {
      scrollDiv.current.scrollLeft += scrollOffset;
    }
  };
  return (
    <div className={`relative ${Group.length || 'hidden'}`}>
      <button
        onClick={() => scroll(-128)}
        className="hidden md:flex justify-start my-auto -mr-4 bg-white z-10 px-3 py-2 rounded-full text-purple-650 shadow-shadowLeftRight absolute left-0 top-10"
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
      <button
        onClick={() => scroll(128)}
        className=" hidden md:flex  justify-start my-auto -ml-4 bg-white z-10 px-3 py-2 rounded-full text-purple-650 shadow-shadowLeftRight absolute right-0 top-10"
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
      <div className={Group.length > 0 ? 'flex gap-4 w-full justify-start' : 'hidden'}>
        <div
          className="flex gap-4 w-full overflow-x-scroll justify-start"
          id="scroll-container"
          style={{
            scrollSnapType: 'x mandatory',
            scrollBehavior: 'smooth',
          }}
          ref={scrollDiv}
        >
          {!pool
            ? Group.map((group, _index) => {
                return (
                  <div
                    key={group.group_id}
                    className={`bg-cover group-btn h-18 md:h-32 min-w-18 md:min-w-32 flex flex-col justify-center items-center rounded-lg relative cursor-pointer italic ${
                      selected2 === group.group_id
                        ? 'text-white bg-heat-fireMobile md:bg-heat-fire'
                        : 'text-gray-500 bg-heat-dull'
                    }`}
                    style={{ scrollSnapAlign: 'start' }}
                    onClick={() => setHeat(group.group_id)}
                  >
                    <h1 className="font-extrabold text-sm md:text-2xl uppercase -mb-2 md:mb-0">
                      {pool ? 'Pool' : 'Heat'}
                    </h1>
                    <h2 className="italic font-poppins font-black text-2.5xl md:text-5xl uppercase ">
                      {group.group_name.toString().padStart(2, '0')}
                    </h2>
                  </div>
                );
              })
            : Group.map((group, _index) => {
                return (
                  <div
                    key={group.group_name}
                    className={` group-btn md:w-72  md:h-32 w-18 min-h-18 flex flex-col justify-center items-center bg-cover rounded-lg relative cursor-pointer italic ${
                      selected2 === group.group_id
                        ? 'text-white bg-pool-active'
                        : 'text-gray-450 bg-pool-inactive'
                    }`}
                    style={{ scrollSnapAlign: 'start' }}
                    onClick={() => setHeat(group.group_id)}
                  >
                    {selected2 === group.group_id ? (
                      <div
                        className={`flex justify-between items-start w-18 md:w-full px-2 md:px-8  ${
                          pool ? 'flex-col' : 'flex-row'
                        }`}
                      >
                        <div>
                          <p className="text-sm font-poppins md:text-3xl font-black h-full w-14 md:w-full break-all">
                            {group.group_name}
                          </p>
                          {pool ? (
                            <p className="text-right font-bold">{group.parameter}</p>
                          ) : (
                            <p className="text-6xl font-black">&gt;{group.group_id.slice(-1)}</p>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`flex justify-between items-start w-18 md:w-full px-2 md:px-8  ${
                          pool ? 'flex-col' : 'flex-row'
                        }`}
                      >
                        <p className=" text-sm font-poppins md:text-3xl font-black h-full w-14 md:w-full break-all ">
                          {group.group_name}
                        </p>
                        {pool ? (
                          <p className={`text-right font-bold ${pool ? 'ml-auto' : ''}`}>
                            {group.parameter}
                          </p>
                        ) : (
                          <p className="text-6xl font-black">&gt;{group.group_id.slice(-1)}</p>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
}
