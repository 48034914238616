import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './App.module.scss';
import Column from './components/column';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const KnockoutComponent = ({ data }) => {
  // console.log(data);

  const boardRef = useRef(null);

  const scrollLeft = () => {
    boardRef.current.scrollTo({
      left: boardRef.current.scrollLeft - 340,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    boardRef.current.scrollTo({
      left: boardRef.current.scrollLeft + 340,
      behavior: 'smooth',
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      {/* <ScrollPanel board={data} boardRef={boardRef} /> */}
      <button
        className="left"
        onClick={scrollLeft}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px',
          position: 'absolute',
          left: '-8px',
          top: '45px',
          zIndex: '10',
          background: 'white',
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          boxShadow: '0 0 10px rgb(0 0 0 / 20%)',
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} style={{ height: '15px' }} />
      </button>

      <button
        className="right"
        onClick={scrollRight}
        style={{
          padding: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0 0 10px rgb(0 0 0 / 20%)',
          position: 'absolute',
          right: '-8px',
          top: '45px',
          zIndex: '10',
          background: 'white',
          width: '40px',
          height: '40px',
          borderRadius: '50%',
        }}
      >
        <FontAwesomeIcon icon={faChevronRight} style={{ height: '15px' }} />
      </button>
      <br />
      <div className={styles.board} ref={boardRef}>
        {data.columns.map((column, colIndex) => (
          <Column
            column={column}
            hasThird={data.hasThird}
            third={data.third}
            isLast={colIndex === data.columns.length - 1}
            isFirst={colIndex === 0}
            key={colIndex}
            index={colIndex}
          />
        ))}
      </div>
    </div>
  );
};

// const fet = () => {
//   fetch('./data.json').then(response => {
//     // console.log(response.json());
//     return response.json();
//   }).then(b => {
//     setBoard(b);
//   }).catch(err => {
//     console.error(err);
//   });
// }

KnockoutComponent.defaultProps = {
  data: {
    title: 'Loading',
    columns: [
      {
        title: 'Data',
        cards: [],
      },

      {
        title: 'Is',
        cards: [],
      },
      {
        title: 'Loading!',
        cards: [],
      },
    ],
    hasThird: true,
  },
};

KnockoutComponent.propTypes = {
  data: PropTypes.object,
};

export default KnockoutComponent;
