import { useQuery } from 'react-query';
import {
  getKnockout,
  getMedalsList,
  getOverview,
  getParticipantsList,
  getTabsRoundsList,
} from '../services';
import QUERY_KEYS from './queryKeys';

/**
 * Function to get user profile details for logged-in user
 * @returns react-query object
 */

export const useOverview = (params) => {
  return useQuery([...QUERY_KEYS.GET_OVERVIEW, params.sport_event], () => getOverview(params), {
    retry: false,
  });
};

export const useTabsRoundsList = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TABS_ROUNDS_LIST, params.sport_event],
    () => getTabsRoundsList(params),
    {
      retry: false,
    }
  );
};

export const useMedalsList = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_MEDALS_LIST, params.sport_event],
    () => getMedalsList(params),
    {
      retry: false,
    }
  );
};

export const useParticipantsList = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_PARTICIPANTS_LIST, params?.group_id, params?.sport_event, params?.tab_id],
    () => getParticipantsList(params),
    {
      retry: false,
    }
  );
};

export const useKnockout = (params) => {
  return useQuery([...QUERY_KEYS.GET_KNOCKOUT, params.sport_event], () => getKnockout(params), {
    retry: false,
  });
};
