const MODULE_NAME = 'profile';

function generateKeyName(value) {
  return [MODULE_NAME, value];
}

const QUERY_KEYS = {
  GET_OVERVIEW: generateKeyName('overview'),
  GET_TABS_ROUNDS_LIST: generateKeyName('tabs-rounds'),
  GET_MEDALS_LIST: generateKeyName('medals'),
  GET_PARTICIPANTS_LIST: generateKeyName('participants'),
  GET_KNOCKOUT: generateKeyName('knockout'),
};

export default QUERY_KEYS;
