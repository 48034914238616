import React, { useEffect } from 'react';
import cardStyle from './styles/card.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';
import trophy from './images/trophy.png';
import PropTypes from 'prop-types';

import Modal from './modal';

const Card = ({ card }) => {
  const zeroPad = (num, places) => String(num).padStart(places, '0');
  const [showScore, setShowScore] = React.useState(false);
  const [modal, setModal] = React.useState(null);
  const date = new Date(card.date);

  const getTeam = (team, teamIndex) => (
    <div key={teamIndex} title={`Team: ${team.teamName}`}>
      {/* <div className={cardStyle.teamName} title={team.teamName}> */}
      {/*  {team.teamName} */}
      {/* </div> */}
      <div
        className={team.winner ? `${cardStyle.players} ${cardStyle.winner}` : cardStyle.players}
        key={teamIndex}
      >
        {team.players.length >= 0 ? (
          team.players.map((player, playerIndex) => {
            return (
              <div key={playerIndex}>
                <div className={cardStyle.item}>
                  <div className={cardStyle.content}>
                    <div
                      className={cardStyle.image}
                      style={{
                        backgroundImage: `url("${player.image}")`,
                        backgroundSize: `cover`,
                        backgroundPosition: `center`,
                      }}
                    />
                    <div className={cardStyle.textContent}>
                      <div className={cardStyle.title}>
                        {player.name}
                        &nbsp; &nbsp;
                        {team.winner ? <img src={trophy} alt={'trophy'} width="35" /> : null}
                      </div>
                      <div className={cardStyle.subtitle}>{player.desc}</div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div>
            <div className={cardStyle.item} />
            <div className={cardStyle.item} />
          </div>
        )}
      </div>
    </div>
  );
  const emptyTeam = () => (
    <div>
      <div className={cardStyle.players}>
        <div>
          <div className={cardStyle.item} />
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (showScore) {
      setModal(<Modal modalType="score" card={card} setShowModal={setShowScore} />);
    } else {
      setModal(null);
    }
  }, [showScore, card]);
  return (
    <div className={cardStyle.card}>
      <div className={cardStyle.section}>
        {modal}

        {card.teamList.length === 2 ? (
          <>
            {card.teamList
              .sort((a, b) => {
                const key1 = a.priority;
                const key2 = b.priority;
                return key2 - key1;
              })
              .map((team, teamIndex) => getTeam(team, teamIndex))}
          </>
        ) : card.teamList.length === 1 ? (
          card.teamList[0].priority === 0 ? (
            [emptyTeam(), getTeam(card.teamList[0], 0)]
          ) : (
            [getTeam(card.teamList[0], 0), emptyTeam()]
          )
        ) : card.teamList.length === 0 ? (
          [emptyTeam(), emptyTeam()]
        ) : null}

        {/*  {card.teamList.length >= 1 ? ( */}
        {/*    card.teamList.map((team, teamIndex) => { */}
        {/*      return ( */}
        {/*        <> */}
        {/*         {card.teamList.length === 1 ? ( */}
        {/*            <div> */}
        {/*              <div className={cardStyle.players}> */}
        {/*                <div> */}
        {/*                  <div className={cardStyle.item} /> */}
        {/*                </div> */}
        {/*              </div> */}
        {/*            </div> */}
        {/*          ) : null} */}
        {/*        </> */}
        {/*      ); */}
        {/*    }) */}
        {/*  ) : card.teamList.length === 0 ? ( */}
        {/*    <div className={cardStyle.players}> */}
        {/*      <div> */}
        {/*        <div className={cardStyle.item} /> */}
        {/*        <div className={cardStyle.item} /> */}
        {/*      </div> */}
        {/*    </div> */}
        {/*  ) : null} */}
      </div>

      {card.matchStatus === 'DIGITAL_SCORE' ? (
        <div className={`${cardStyle.footer} ${cardStyle.footerMatchDone}`}>
          <div className={cardStyle.date}>
            {zeroPad(date.getDate(), 2) +
              '-' +
              zeroPad(date.getMonth() + 1, 2) +
              '-' +
              zeroPad(date.getFullYear().toString().slice(2, 4), 2)}
            &nbsp; &nbsp;
            {date.toLocaleTimeString('en-US', {
              hour12: true,
              hour: 'numeric',
              minute: 'numeric',
            })}
          </div>
        </div>
      ) : card.matchStatus === 'COMPLETED' ? (
        <div className={cardStyle.footer}>
          <div className={cardStyle.date}>
            {zeroPad(date.getDate(), 2) +
              '-' +
              zeroPad(date.getMonth() + 1, 2) +
              '-' +
              zeroPad(date.getFullYear().toString().slice(2, 4), 2)}
            &nbsp; &nbsp;
            {date.toLocaleTimeString('en-US', {
              hour12: true,
              hour: 'numeric',
              minute: 'numeric',
            })}
          </div>
          {card.teamList.length > 0 ? (
            // <div className={cardStyle.scores} onClick={() => setShowScore(true)}>
            <div className={cardStyle.scores}>{card.score}</div>
          ) : null}
        </div>
      ) : card.matchStatus === 'NO_SHOW' ? (
        <div className={`${cardStyle.footer} ${cardStyle.footerMatchDone}`}>
          <div className={cardStyle.date}>
            {zeroPad(date.getDate(), 2) +
              '-' +
              zeroPad(date.getMonth() + 1, 2) +
              '-' +
              zeroPad(date.getFullYear().toString().slice(2, 4), 2)}
            &nbsp; &nbsp;
            {date.toLocaleTimeString('en-US', {
              hour12: true,
              hour: 'numeric',
              minute: 'numeric',
            })}
          </div>
          <h4>No Show</h4>
        </div>
      ) : card.matchStatus === 'WALKOVER' ? (
        <div className={`${cardStyle.footer} ${cardStyle.footerMatchDone}`}>
          <div className={cardStyle.date}>
            {zeroPad(date.getDate(), 2) +
              '-' +
              zeroPad(date.getMonth() + 1, 2) +
              '-' +
              zeroPad(date.getFullYear().toString().slice(2, 4), 2)}
            &nbsp; &nbsp;
            {date.toLocaleTimeString('en-US', {
              hour12: true,
              hour: 'numeric',
              minute: 'numeric',
            })}
          </div>
          <h4>Walkover</h4>
        </div>
      ) : card.matchStatus === 'BYE' ? (
        <div className={`${cardStyle.footer} ${cardStyle.footerMatchDone}`}>
          <div className={cardStyle.date}>
            {zeroPad(date.getDate(), 2) +
              '-' +
              zeroPad(date.getMonth() + 1, 2) +
              '-' +
              zeroPad(date.getFullYear().toString().slice(2, 4), 2)}
            &nbsp; &nbsp;
            {date.toLocaleTimeString('en-US', {
              hour12: true,
              hour: 'numeric',
              minute: 'numeric',
            })}
          </div>
          <h4>Bye</h4>
        </div>
      ) : card.matchStatus === 'DIGITAL_SCORING_IN_PROGRESS' ? (
        <div className={`${cardStyle.footer} ${cardStyle.footerMatchDone}`}>
          <FontAwesomeIcon icon={faSpinner} style={{ height: '20px', color: 'white' }} pulse />
          <h5>Please Wait, Someone is already Scoring</h5>
        </div>
      ) : null}
    </div>

    // <div className={cardStyle.card">
    //   <div className='top'>
    //     <div className='text'>{card.id}</div>
    //   </div>
    //   <div className='section'>
    //
    //     {
    //       card.players.map(player=>(
    //         <Item item={player}/>
    //       ))
    //     }
    //
    //   </div>
    //   <div className={cardStyle.footer">
    //
    //   </div>
    // </div>
  );
};

Card.propTypes = {
  card: PropTypes.object.isRequired,
};

export default Card;
