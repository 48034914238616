import React, { useEffect } from 'react';
import ListLoader from '../../components/ListLoader';
import gold from '../../assets/img/gold.svg';
import silver from '../../assets/img/silver.svg';
import bronze from '../../assets/img/bronze.svg';
// import { getTime } from 'constants/DateFunctions';

export default function MedalsFinals({ medalsListQueryData, isValid, _showMedals }) {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
  }, []);
  // mobile version
  if (windowWidth < 768 && !isValid && medalsListQueryData.data)
    return (
      <div className="px-4 py-2 border border-gray-150 rounded-lg">
        <div className="text-gray-450 font-bold pb-2 border-b border-gray-150">Winners</div>
        <div className="flex flex-col gap-2 py-2">
          {
            <ListLoader queryData={medalsListQueryData} queryKey="id">
              {({ item }) => {
                return (
                  <div className="flex gap-2 items-center justify-between w-full">
                    <div className="flex gap-2 items-center">
                      <img
                        src={
                          item.medal === 'Gold' ? gold : item.medal === 'Silver' ? silver : bronze
                        }
                        alt=" "
                        className="w-6 h-6"
                      />
                      <div className="flex flex-col">
                        <p className="font-bold text-gray-750 text-base lg:text-2xl inline-block">
                          {item?.name}
                        </p>
                        <p className="text-xxs text-gray-450 inline-block">{item?.institution}</p>
                      </div>
                    </div>
                    <div>
                      {item.winning_factor ? (
                        <p className="text-xxs text-gray-450 inline-block">
                          {item?.winning_factor}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                );
              }}
            </ListLoader>
          }
        </div>
      </div>
    );

  return (
    <div>
      {!isValid && medalsListQueryData.data ? (
        <div className="divide-x divide-gray-200 bg-white flex mb-8 gap-4 flex-row border-1 border-gray-250 justify-around rounded-lg items-center">
          {
            <ListLoader queryData={medalsListQueryData} queryKey="id">
              {({ item }) => {
                return (
                  <div className="w-1/3 py-5 flex gap-4 justify-center items-center">
                    <img
                      src={item.medal === 'Gold' ? gold : item.medal === 'Silver' ? silver : bronze}
                      alt=" "
                      className="w-16 h-16"
                    />
                    <div className="flex flex-col">
                      <p className="font-bold text-gray-750 text-base lg:text-2xl inline-block">
                        {item?.name}
                      </p>
                      <p className="text-xs text-gray-650 inline-block">{item?.institution}</p>
                      {item.winning_factor ? (
                        <p className="text-xs text-gray-650 inline-block">{item?.winning_factor}</p>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                );
              }}
            </ListLoader>
          }
        </div>
      ) : null}
    </div>
  );
}
