import React from 'react';
import ListLoader from '../../components/ListLoader';
import { getStartAndEndDateWithTime } from 'constants/DateFunctions';

export default function TabsRounds({
  tabRoundsListQueryData,
  setTabs,
  selected,
  setGroupOnTabs,
  setShowMedals = () => {},
}) {
  return (
    <div className="no-scrollbar md:hidden mb-2">
      <div className="flex w-full lg:mt-0 lg:mx-0 font-roboto profile-subnav justify-between cursor-pointer bg-white text-center overflow-x-auto no-scrollbar">
        <ListLoader queryData={tabRoundsListQueryData} queryKey="id">
          {({ item, _idx }) => {
            return (
              <div
                onClick={() => {
                  setTabs(item.tab_id);
                  setGroupOnTabs(item.groups);
                  if (item.medals === true) {
                    setShowMedals(true);
                  } else {
                    setShowMedals(false);
                  }
                }}
                key={item?.tab_id}
                className={
                  selected === item.tab_id
                    ? 'border-purple-650 border-b-4 font-bold flex-grow min-w-max cursor-pointer px-4 py-2'
                    : 'text-gray-450 min-w-max border-b-1 border-gray-450 flex-grow cursor-pointer px-4 py-2'
                }
              >
                <h2
                  className={
                    selected === item.tab_id
                      ? 'text-purple-650 font-bold'
                      : 'text-gray-450 font-bold'
                  }
                >
                  {item?.tab_name}
                </h2>
                {item.start_date ? (
                  <p className="mt-1 text-xs text-gray-400 font-poppins font-normal">
                    {getStartAndEndDateWithTime(item?.start_date, item?.end_date)}
                  </p>
                ) : (
                  ''
                )}
              </div>
            );
          }}
        </ListLoader>
      </div>
    </div>

    /*
            <ListLoader queryData={tabRoundsListQueryData} queryKey="id">
              {({ item }) => {
                return (
                  <div
                    key={item?.tab_id}
                    className={`${
                      enableScrollButtons ? 'md:w-30% w-47% flex-shrink-0' : 'w-full'
                    } justify-between flex gap-5`}
                  >
                    <button
                      key={item?.tab_id}
                      className={`tabs py-3 w-full bg-white items-center rounded-lg ${
                        variants[variant].border
                      } ${
                        selected === item.tab_id
                          ? `tabs shadow-purpleDrop border-purple-650 ${variants[variant].selectedBG}`
                          : `shadow-none border-gray-250 ${variants[variant].bg}`
                      } ${item?.start_date ? 'min-h-24' : 'h-full'}`}
                      }
                    >
                      <h3
                        className={` ${
                          selected === item.tab_id
                            ? `${variants[variant].selectedText}`
                            : `${variants[variant].text}`
                        }`}
                      >
                        {item?.tab_name}
                      </h3>
                      {item.start_date ? (
                        <p className="mt-1 text-xs text-gray-400">
                          {getStartAndEndDateWithTime(item?.start_date, item?.end_date)}
                        </p>
                      ) : (
                        ''
                      )}
                    </button>
                  </div>
                );
              }}
            </ListLoader>
          </div>
        </div>
    */
  );
}
