import React from 'react';
import Card from './card';
import columnStyle from './styles/column.module.scss';
import PropTypes from 'prop-types';

const Column = ({ column, hasThird, third, isLast, index }) => {
  return (
    <div className={columnStyle.columnDiv}>
      <div className={columnStyle.btnDiv} key={index}>
        <div
          className={columnStyle.button}
          style={{
            background: `url(/assets/sfa_online/node_modules/knockout-component/src/direflow-components/knockout-component/components/images/pattern.png) no-repeat -5% , conic-gradient(from 51.11deg at 50% 58.75%, #8E2DE2 0deg, #4A00E0 360deg)`,
          }}
        >
          {column.title}
        </div>
      </div>
      <div className={columnStyle.column}>
        {/* <h1>{column.title}</h1> */}
        {column.cards.map((card, index) => {
          if (isLast && hasThird && index === 0) {
            return (
              <div className={columnStyle.headingCardGroup} key={index}>
                <h1>{column.title}</h1>
                <Card card={card} key={index + 'apple'} />
                <h1>Third</h1>
                <Card card={third} key={index} />
              </div>
            );
          } else if (index === 0) {
            return (
              <div className={columnStyle.headingCardGroup} key={index}>
                <h1>{column.title}</h1>
                <Card card={card} key={index} />
              </div>
            );
          } else {
            return <Card card={card} key={index} />;
          }
        })}
      </div>
    </div>
  );
};

Column.propTypes = {
  column: PropTypes.object.isRequired,
  hasThird: PropTypes.bool.isRequired,
  third: PropTypes.object,
  isLast: PropTypes.bool.isRequired,
  isFirst: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
};

export default Column;
