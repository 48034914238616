import React from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import ProfileBar from '../profiles/Components/ProfileBar';
import DirectFinals from './DirectFinals';
import Heat from './Heat';
import League from './League';
import LeagueKnockout from './LeagueKnockout';
import QualifyingFinal from './QualifyingFinal';
import QualifyingKnockout from './QualifyingKnockout';
import QualifyingN from './QualifyingN';
import Knockout from './Knockout';
import Navbar from '../micro-site/Components/Navbar';
import ScrollToTop from 'micro-site/Components/ScrollToTop';
import { useTournamentDetails } from '../micro-site/queries/hooks';
import PlaceHolder from './Component/PlaceHolder';
import Loader from '../components/Loader';
import QualifyingRoundHighJumpLongJump from './QualifyingRoundHighJumpLongJump';
import DirectFinalsShooting from './DirectFinalsShooting';
import SwissLeague from './SwissLeague';
import WeightIn from './WeighIn';
import CustomSwitch from 'components/routes/CustomSwitch';

export default function Profile() {
  const base = useRouteMatch('/:user/:type');
  const base2 = useRouteMatch('/:user/:type/:scoreType/:sportEvent');
  const base3 = useRouteMatch('/:user/:type/:scoreType1/:scoreType2/:sportEvent');
  console.log(base2.params.sportEvent.split('-')[0]);
  const tournamentDetailsQuery = useTournamentDetails({
    tournament_id:
      base2.params.sportEvent.split('-')[0] !== ' long jump )' &&
      base2.params.sportEvent.split('-')[0] !== ' time trials'
        ? base2.params.sportEvent.split('-')[0]
        : base3.params.sportEvent.split('-')[0],
  });

  // console.log(base3);
  // /public/score/:format/:sport-event
  return tournamentDetailsQuery.isLoading ? (
    <Loader />
  ) : (
    <div className={'bg-white md:bg-transparent'}>
      <Navbar fromDigitalScoring />
      <ProfileBar head={tournamentDetailsQuery.data?.name} />
      <div
        style={{
          maxWidth: location.pathname.includes(base.url + '/knockout/') ? '95%' : '1140px',
        }}
        className="lg:mx-auto lg:mt-8 mx-2"
      >
        <CustomSwitch>
          <Route exact path={`${base.url}`}>
            <Redirect to={`${base.url}/heat`} />
          </Route>
          <Route path={`${base.url}/heats / time trials/:sportEvent`} component={Heat} />
          <Route
            path={`${base.url}/qualifying round (high jump / long jump )/:sportEvent`}
            component={QualifyingRoundHighJumpLongJump}
          />
          <Route path={`${base.url}/league cum knockout/:sportEvent`} component={LeagueKnockout} />
          <Route path={`${base.url}/league/:sportEvent`} component={League} />
          <Route
            path={`${base.url}/qualifying round - knockout/:sportEvent`}
            component={QualifyingKnockout}
          />
          <Route path={`${base.url}/qualifying_n/:sportEvent`} component={QualifyingN} />
          <Route path={`${base.url}/qualifying_final/:sportEvent`} component={QualifyingFinal} />
          <Route path={`${base.url}/direct finals/:sportEvent`} component={DirectFinals} />
          <Route
            path={`${base.url}/direct finals - shooting/:sportEvent`}
            component={DirectFinalsShooting}
          />
          <Route path={`${base.url}/swiss league/:sportEvent`} component={SwissLeague} />
          <Route
            path={`${base.url}/qualifying_knockout/:sportEvent`}
            component={QualifyingKnockout}
          />
          <Route path={`${base.url}/knockout/:sportEvent`} component={Knockout} />
          <Route path={`${base.url}/weigh in/:sportEvent`} component={WeightIn} />
          <Route path={`${base.url}/*/:sportEvent`} component={PlaceHolder} />
        </CustomSwitch>
      </div>
      <ScrollToTop />
    </div>
  );
}
