import React, { useEffect, useMemo, useState } from 'react';
import EventName from '../Component/EventName';
import Table from '../Component/Table';
import {
  useMedalsList,
  useParticipantsList,
  useTabsRoundsList,
} from '../queries/digitalScoringHooks';
import TabsRounds from '../Component/TabsRounds';
import TabsRoundsMobile from '../Component/TabsRoundsMobile';
import Groups from '../Component/Groups';
import MedalsFinals from '../Component/MedalsFinals';
import { useRouteMatch } from 'react-router-dom';
import DetailItemLoader from 'components/DetailItemLoader';
import MobileTable from '../Component/MobileTable';
import MobileCard from '../Component/MobileCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default function QualifyingRoundHighJumpLongJump() {
  // console.log('QualifyingKnockout');
  const base = useRouteMatch('/:user/:type/:scoreType1/:scoreType2/:sportEvent');

  // const params = useParams();
  const tabRoundsListQueryData = useTabsRoundsList({
    sport_event: base.params.sportEvent,
  });
  const medalsListQueryData = useMedalsList({
    sport_event: base.params.sportEvent,
  });
  // console.log(medalsListQueryData.data.error.length);
  console.log(base.params.sportEvent);
  // console.log(medalsListQueryData);
  const [selected, setSelected] = useState('');
  const [selected2, setSelected2] = useState('');

  // console.log(tabRoundsListQueryData);

  useEffect(() => {
    tabRoundsListQueryData.data
      ? setSelected(tabRoundsListQueryData?.data[0]?.tab_id)
      : setSelected('');
  }, [tabRoundsListQueryData.isFetched, tabRoundsListQueryData.data]);

  useEffect(() => {
    tabRoundsListQueryData.data
      ? setSelected2(tabRoundsListQueryData?.data[0]?.groups[0]?.group_id)
      : setSelected2('');
  }, [selected]);

  const participantsListQueryData = useParticipantsList({
    sport_event: base.params.sportEvent,
    tab_id: selected,
    group_id: selected2 || '',
  });

  useEffect(() => {
    participantsListQueryData.refetch();
    // console.log(participantsListQueryData.data);
  }, [selected, selected2, base.params.sportEvent]);

  const [Group, setGroup] = useState([]);
  const setTabs = (idx) => {
    setSelected(idx);
    let i;
    for (i = 0; i < tabRoundsListQueryData.data.length; i++) {
      if (tabRoundsListQueryData.data[i].tab_id === idx) {
        setSelected2(tabRoundsListQueryData?.data[i]?.groups[0]?.group_id);
        break;
      }
      // setSelected2(tabRoundsListQueryData?.data[0]?.groups[0]?.group_id);
    }
  };

  useEffect(() => {
    if (tabRoundsListQueryData.isFetched) {
      document.querySelector('.tabs')?.click();
    }
  }, [tabRoundsListQueryData.isLoading]);

  const setHeat = (idx) => {
    setSelected2(idx);
  };

  const setGroupOnTabs = (group) => {
    setGroup(group);
  };
  console.log(base?.params);
  const columns = [
    {
      label: '',
      component: 'IndexColumn',
    },
    {
      key: 'sfa_id',
      label: 'SFA ID',
      component: 'TextColumn',
    },
    {
      key: 'athlete_name',
      label: 'Athlete Name',
      component: 'TextAndImage',
    },
    {
      key: 'institution',
      label: 'Institution',
      component: 'TextAndImage',
    },
    {
      key: 'number_of_attempts',
      label: base?.params?.sportEvent?.toLowerCase().includes('high jump')
        ? 'Number Of Jumps'
        : 'Best Attempt',
      optionalClasses: 'block-flex flex-nowrap items-center p-4',
      component: 'TextColumn',
      hideDecimal: base?.params?.sportEvent?.toLowerCase().includes('high jump'),
    },
    {
      key: 'result',
      label: 'Result',
      component: 'ResultButton',
    },
  ];
  const hideRank = useMemo(() => {
    if (participantsListQueryData.isFetched && participantsListQueryData?.data) {
      return (
        Array.isArray(participantsListQueryData?.data) &&
        participantsListQueryData?.data?.find((el) => !el.rank && el.rank !== 0)
      );
    }
  }, [participantsListQueryData?.data]);
  if (hideRank) delete columns[0];
  // const columns3 = [
  //   {
  //     key: 'match_no',
  //     label: 'Match #',
  //     component: 'TextColumn',
  //   },
  //   {
  //     key: 'match_round',
  //     label: 'Round',
  //     component: 'TextColumn',
  //   },
  //   {
  //     key: 'date_time',
  //     label: 'Date and Time',
  //     component: 'DateTimeColumn',
  //   },
  //   {
  //     key: 'opponent_1',
  //     label: 'Opponent 1',
  //     component: 'OpponentColumn',
  //   },
  //   {
  //     key: 'opponent_2',
  //     label: 'Opponent 2',
  //     component: 'OpponentColumn',
  //   },
  //   {
  //     key: 'score',
  //     label: 'Score',
  //     component: 'TextColumn',
  //   },
  // ];

  // console.log(tabRoundsListQueryData?.data[tabRoundsListQueryData?.data?.length - 1].tab_id);
  // console.log(typeof participantsListQueryData?.data[0].score);
  return (
    <div>
      <EventName sport_event={base.params.sportEvent} />
      {tabRoundsListQueryData?.data?.length > 0 ? (
        <>
          <TabsRounds
            tabRoundsListQueryData={tabRoundsListQueryData}
            setTabs={setTabs}
            selected={selected}
            setGroupOnTabs={setGroupOnTabs}
            variant={'purple'}
            enableScrollButtons
          />
          <TabsRoundsMobile
            tabRoundsListQueryData={tabRoundsListQueryData}
            setTabs={setTabs}
            selected={selected}
            setGroupOnTabs={setGroupOnTabs}
            variant={'purple'}
            enableScrollButtons
          />
        </>
      ) : null}
      {Group.length > 0 ? (
        <Groups Group={Group} setHeat={setHeat} selected2={selected2} pool={true} />
      ) : null}
      {selected === 'winners' ? (
        medalsListQueryData?.data?.length > 0 ? (
          <MedalsFinals
            medalsListQueryData={medalsListQueryData}
            isValid={medalsListQueryData?.data?.error?.length}
            Group={Group}
          />
        ) : (
          <div className="text-center bg-white px-2 py-4">
            Medal Winners Are Yet To Be Declared.
          </div>
        )
      ) : null}
      <div className="flex justify-between items-center p-4 hidden -mb-8">
        <p className="font-bold">Match Details</p>
        <FontAwesomeIcon icon={faSearch} className="text-gray-450" />
      </div>
      <DetailItemLoader queryData={participantsListQueryData}>
        {({ data }) =>
          data && data.length > 0 ? (
            <>
              <div className="my-8 p-5 bg-gradient-to-br from-blue-75 to-purple-75 rounded-lg hidden md:block">
                <Table
                  rows={data}
                  columns={
                    selected === 'winners' ? [] : columns
                    // selected === 'matches' ? columns2 : selected === 'knockout' ? columns3 : columns
                  }
                />
              </div>
              {data.map((item, index) =>
                item.opponent_1 ? (
                  <MobileTable key={index} Data={item} />
                ) : (
                  <MobileCard key={index} Data={item} />
                )
              )}
            </>
          ) : (
            <div className="my-8 p-5 bg-gradient-to-br from-blue-75 to-purple-75 rounded-lg">
              <div
                className={`text-center p-8 text-white font-bold rounded-lg ${
                  selected === 'winners' ? 'hidden' : ''
                }`}
                style={{
                  background: `url(/assets/sfa_online/node_modules/knockout-component/src/direflow-components/knockout-component/components/images/pattern.png) no-repeat -5% , conic-gradient(from 51.11deg at 50% 58.75%, #8E2DE2 0deg, #4A00E0 360deg)`,
                }}
              >
                Draws & Schedules will be updated soon. Thank you for your patience.
              </div>
            </div>
          )
        }
      </DetailItemLoader>
    </div>
  );
}
